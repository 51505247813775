.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #e7e7e7;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:nth-child(odd) {
  background-color: white;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #FFFFFF;
  color: black;
}


#eyeprescription {
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  border-collapse: separate;
  margin-top: 15px;
}

#eyeprescription td,
#eyeprescription th {
  border: 1px solid #c4b8b8;
  padding: 8px;
  /* border-collapse: collapse; */
  font-size: 14px;
  font-weight: bold;
}

#eyeprescription tr:nth-child(even) {
  background-color: #f2f2f2;
}

#eyeprescription tr:nth-child(odd) {
  background-color: white;
}

#eyeprescription th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #FFFFFF;
  color: black;
  font-size: 13px;
  font-weight: bold;
}


#tabledesign {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#tabledesign td,
#tabledesign th {
  border: 1px solid #e7e7e7;
  padding: 8px;
}

#tabledesign tr:nth-child(even) {
  background-color: #f2f2f2;
}

#tabledesign tr:nth-child(odd) {
  background-color: white;
}

#tabledesign th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #FFFFFF;
  color: black;
}








.flex {
  display: flex;
}





#checkouttable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#checkouttable td,
#checkouttable th {
  border: 1px solid #989c9e;
  padding: 8px;
}

#checkouttable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#checkouttable tr:nth-child(odd) {
  background-color: rgb(252, 244, 244);
}

#checkouttable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #3c5bf7;
  color: white;
}



.column {
  display: flex;
  flex-direction: column;
}

.mobile-row {
  display: flex;
}

.half-width {
  width: 50%;
}

.m-20 {
  margin: 20px;
}

.m-16 {
  margin: 16px;
}

.m-12 {
  margin: 12px;
}

.spacer {
  justify-content: space-between;
}

.doctor-img {
  width: 120px;
  border-radius: 6px;
  margin: 12px;
  /* style={{ height: '80px', borderRadius: '4px' }} */
}

.main-box {
  width: 100%;
}

.hovericonactive:hover {
  cursor: pointer;



}

.hovericondeactive:hover {
  cursor: pointer;



}

.hovericonactive {
  color: white;
  height: 80px;
  width: 182px;
  background-color: #3835F4;
  margin-left: 20px;
  border-radius: 8px;
  border: 2px solid #E0E8F3;
  box-shadow: 1px 1px 1px 2px rgb(242, 244, 242);
  margin-top: 22px;
}

.hovericondeactive {
  height: 80px;
  width: 182px;
  background-color: #EFEFEF;
  margin-left: 20px;
  border-radius: 8px;
  border: 2px solid #E0E8F3;
  box-shadow: 1px 1px 1px 2px rgb(242, 244, 242);
  margin-top: 22px
}

.appointment-box {
  margin-left: 12px;
  width: 70%;
}

@media screen and (max-width: 720px) {
  .mobile-row {
    display: flex;
    flex-direction: column;
  }

  .doctor-img {
    padding: 8px;
    width: 100%;
  }

  .main-box {
    width: 100%;
  }

  .appointment-box {
    margin-left: 0px;
    width: 94%;
  }

  .hovericonactive {
    display: flex;
    flex-direction: column;
    height: 80px;
    width: 182px;

    margin-left: 20px;

    margin-top: 22px;
  }
}

.newRegistration {
  margin-top: 15px;
  height: 40px;
  border-style: solid;

}

.newRegistration:hover {
  background-color: #10305e;

}

.newRegistration a:hover {
  color: white
}

.loginCard {
  width: 1080px;
  height: 697px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 70px
}

.loginImage {
  margin-top: -140px;

}

@media screen and (max-width: 768px) {


  .loginCard {
    width: 1233px;
    height: 1388px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 334px;
  }

  /* .col-lg-8 {
    height: 950px;
    width: 950px;
  } */
  .loginImage {
    width: 700px;
    height: 700px
  }
}

@media screen and (max-width: 580px) {

  .loginImage {
    visibility: hidden;
    width: 0px;
  }

  .loginCard {
    width: 350px;
    height: 780px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 70px
  }


}



.clinicButton {
  height: 35px;
  width: 235px;
  background-color: rgb(180, 177, 177);
  color: #ffffff
}

.clinicButton:hover {
  background-color: blueviolet;
}

.dropdown-menu {
  position: absolute;
  will-change: transform;
  top: -11px;
  left: -79px;
  transform: translate3d(0px, 26px, 0px);
}

.dropdown-menushow {
  position: absolute;
  transform: translate3d(0px, 26px, 0px);
  top: -11px;
  left: -79px;
  will-change: transform;

}

.resendbutton:hover{
 cursor: pointer;
 color: #10305e;
}

.resend:hover{
  cursor: pointer;
  color:#10305e;
}

.commonbutton{
  height:40px;
  width:140px;
  border-radius:5px;
  font-size:12px;
  color:blue;
  border:1px solid skyblue;
  background-color:white;
}
.commonbutton:hover{
  cursor: pointer;
}