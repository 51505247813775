.page-fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.page-slide-up {
  opacity: 0;
  transform: translateY(100%);

  animation: slideUp 0.5s ease-in-out forwards;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.flip {
  transform-style: preserve-3d;
  animation: flip 0.5s ease-in-out forwards;
}

@keyframes flip {
  from {
    transform: rotateY(0);
  }

  to {
    transform: rotateY(180deg);
  }
}

.animated-item {
  animation: slideIn 3s;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.content {
  animation: move 1s forwards;
}

/* .left-to-right {
  animation: move 2s linear;
} */

@keyframes move {
  0% {
    right: -100px;
  }

  100% {
    right: 50%;
  }
}
