x .app {
  /* text-align: center; */
  margin: 0 auto;
  width: 50%;
}

.selected-date {
  margin: 0 auto;
}

.times button {
  display: block;
  background-color: #1457ff;
  color: white;
  margin-top: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* react calendar */
.react-calendar {
  max-width: 100%;
  background-color: #e2e9ef;
  color: black;
  padding: 20px;
  font-size: 20px;
  border-radius: 8px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation button {
  color: black;
  min-width: 44px;
  background: none;

  font-size: 26px;
  margin-top: 8px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
}

.react-calendar__navigation button {
  min-width: 44px;
  /* background: none; */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  color: rgb(26, 23, 23);
}

.react-calendar__navigation button {
  background-color: #96adc6;
  margin: 1px;
  color: rgb(18, 17, 17);
  border-radius: 4px;
}

.react-calendar__navigation button[active] {
  background-color: #bf154e;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #cee2f8;
  color: rgb(8, 8, 8);
  font-size: 0.8em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
  /* color: black; */
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: gray;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}

.react-calendar__tile:disabled {
  background-color: #fff0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #e6e6e6;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #1087ff;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.wrapper-progressBar {
  width: 100%;
  padding: 12px 24px;
  text-align: center;
  font-size: 12px;
  text-align: center;
}

.progressBar li {
  list-style-type: none;
  float: left;

  width: 25%;
  position: relative;
  text-align: center;
}

.progressBar li:before {
  content: " ";
  line-height: 30px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  display: block;
  text-align: center;
  margin: 0 auto 10px;
  background-color: white;
}

.progressBar li:first-child:after {
  content: none;
}

.progressBar li.active {
  color: dodgerblue;
}

.progressBar li.active:before {
  border-color: #1457ff;
  background-color: #1457ff;
  content: "\2713";
  color: white;
}

.progressBar li.unactive:before {
  background-color: gray;
  content: "\2713";
  color: white;
}

.progressBar li.active:after {
  background-color: #1457ff;
}

html {
  scroll-behavior: smooth;
}

.calender-grid {
  display: grid;
  font-size: 16px;
  grid-template-columns: auto auto;
  margin-top: -3px;
}

.booking-item {
  padding: 4px;
  margin: 3px;
  box-shadow: rgba(17, 17, 26, 0.15) 0px 0px 12px;
  min-width: 90px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 4px;
  background-color: #0b58ff;
  color: white;
  cursor: pointer;
}

.disable {
  background-color: gray;
  opacity: 0.6;
  color: white;
}

.disable:hover {
  background-color: gray;
}

@media screen and (max-width: 1180px) {
  .react-calendar {
    width: 90%;
    padding: 20px;
    margin: auto;
    font-size: 16px;
  }

  .calender-grid {
    grid-template-columns: auto;
    font-size: 12px;
  }

  .react-calendar__navigation button {
    font-size: 16px;
  }
}