.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media print {
  #printPageButton {
    display: none;
  }
}

.left {
  text-align: left;
}

.noleft {
  margin-left: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-navbar {
  display: flex;
  flex-direction: row;

}

.nested-box:hover {
  /* margin-top:10px; */
  cursor: pointer;
  /* box-shadow: 2px 2px 2px 2px #cad4f8; */
  border: 1px solid rgb(255, 255, 255);
  border-radius: 5px;
  background-color: #E0E8F3;
  /* color:rgb(247, 245, 245); */
  padding-bottom: 20px;
  /* font-size: 20px; */


}

.feemasterflex {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'space-between';
}

.feemasternonflex {
  display: 'flex';
  flex-direction: 'row';
  justify-content: 'flex-end';
}

.blue-text {
  padding-top: 16px;
  color: #558AFF;
  font-size: 14px;
  font-weight: 600;
}

.blue-custom-text {
  /* padding-top:16px; */
  color: #558AFF;
  font-size: 14px;
  font-weight: 600;
}

.blue-text:hover {
  color: #253992;
}

.card-content {
  display: flex;
  flex-direction: row;
  width: 100%;

}


/* login page */
* {
  margin: 0px;
  padding: 0px;
}

.password-toggle-icon {
  position: absolute;
  margin-top: 10px;
  margin-left: -20px;
  /* top: 8px;
  right:10px; */
  z-index: 1000;
  cursor: pointer;
}


.login {
  margin-top: 30px;
  /* width:320px;
  height:560px; */
  /* padding:30px; */
  margin-left: auto;
  margin-right: auto;
  /* position:absolute; */
  /* top:55%;
  left:50%; */
  /* transform: translate(-50%, -50%); */
  /* text-align: left;
  border-radius: 10px ;
  background: rgb(255, 255, 255); */
}

.login input[type="text"] {
  width: 100%;
  background: #fff;
  color: black;
  padding: 5px 5px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid rgb(79, 203, 224);
  border-radius: 3px;
  /* border-bottom:aliceblue; */
  text-align: left;
  box-sizing: border-box;
  font-size: bold;
}

.login input[type="password"] {
  width: 100%;
  background: #fff;
  color: black;
  padding: 5px 5px;
  margin: 4px 0;
  display: inline-block;
  border: 1px solid rgb(79, 203, 224);
  border-radius: 3px;
  /* border-bottom:aliceblue; */
  text-align: left;
  box-sizing: border-box;
  font-size: bold;
}

.loginbutton {
  width: 100%;
  padding: 7px;
  background: rgba(50, 238, 213, 0.877);
  border-radius: 5px;
  font-size: 18px;
  position: relative;
}

.loginbutton:hover {
  cursor: pointer;
  background-color: aqua;
  color: white;
  border-color: aqua;

}

.image {
  /* margin-top: 10px; */
  height: 80px;
  width: 100%;
  border-radius: 6px;
}

.images {
  width: 60px;
  height: 60px;
  /* align-items: center; */
  /* display: flex; */
  /* float: right; */
  border-radius: 28px;
  margin-top: -12px;
}

.mid-img {
  text-align: center;
}

.like {
  height: 200px;
  margin-top: 20px;
  width: 200px;
  align-items: center;
  border-radius: 140px;
}

.red-alert-text {
  font-size: 13;
  font-weight: 600;
  color: red;
}

.error-text {
  text-align: left;
  margin-left: 10px;
  font-size: 13;
  font-weight: 600;
  color: red;
  padding-bottom: 10px;
}

.divider {
  border: 1px solid #1A967C;
  width: auto;
  margin-top: 15px;
}

.schedule-container {
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.right-align-logout {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;

}

.input-style {
  border: 1px solid rgb(185, 184, 184);
  width: 50%;
  height: 30px;
  padding-left: 8;
  font-size: 12px;
  border-radius: 3;
  padding-left: 5px;
}

.left-input-style {
  border: 1px solid rgb(185, 184, 184);
  width: 50%;
  height: 30px;
  padding-left: 8;
  font-size: 12px;
  border-radius: 3;
  margin-left: 10px;
  padding-left: 5px;

}

.remove-image {

  text-align: right;
  height: 28px;
  width: 28px;
}

.remove-image:hover {
  cursor: pointer;
}

.close-button {
  text-align: right;
  margin-right: 30px;
}

.name {
  font-size: 14px;
  font-weight: bold;
  /* margin-top: 5px; */
}

.doctorsname {
  font-size: 12px;
  font-weight: bold;
  /* margin-top: 5px; */
}

.constid {
  font-size: 12px;
  font-weight: bold;
  /* margin-top: 5px; */
}

.loading {
  margin-top: 20px;
  font-size: 15px;
  font-weight: bold;
  color: #100e9f;
}

.divider-auto {
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
  border: 0.5px solid green;


}

.searching {
  font-size: 12px;
  color: 'green';
  font-weight: bold;
  -top: 5px;
}

.searched {
  font-size: 14px;
  font-weight: bold;
  color: green;
  margin-top: 40px;
  margin-left: 15px;
}

.search-icon {
  height: 15px;
  width: 15px;
  margin-top: 10px;
}

.tableview {
  height: auto;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 20px;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  /* width:90%;
  margin-left: 50px;
  margin-right: 50px; */
  /* width:80%; */
}


/* .page-item{
  background-color:rgb(167, 117, 117);
} */

.medicineview {
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;

  width: auto;
}


/* table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 200px;
  } */

#tabledata {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  border-collapse: collapse;
  border: 1px solid #989c9e;
  width: 'auto';
}

.card-innr:hover {
  cursor: pointer;
}

.doctorlist {
  height: 80px;
  width: '50%';
  background-color: red;
  margin-top: 22px;
}

#tabledata td,
#tabledata th {
  border: 1px solid #989c9e;
  padding: 10px;
}

#tabledata tr:nth-child(even) {
  background-color: #f2e9e9;
}

#tabledata tr:hover {
  background-color: #ddd;
}

#tabledata th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #2c80ffea;
  color: white;
}

#prescriptiondata {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  border-collapse: collapse;
  border: 1px solid #ddd;
  width: 100%;
}

#prescriptiondata td,
#prescriptiondata th {
  border: 1px solid #ddd;
  padding: 10px;
}

#prescriptiondata tr:nth-child(even) {
  background-color: #f2e9e9;
}

#prescriptiondata tr:hover {
  background-color: #ddd;
}

#prescriptiondata th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background-color: #E0E8F3;
  color: #2C80FF;
  font-size: 13px;
  font-weight: 600;
}

#medicinedata th {
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  background-color: #E0E8F3;
  color: #2C80FF;
  font-size: 15px;
  font-weight: 600;
}

.view-details {
  height: 30px;
  width: 'auto';
  padding-left: 20px;
  padding-right: 20px;
  background-color: #74b0ed;
  font-size: small;
  color: white;
  border-radius: 8px;
  border: 1px solid rgb(247, 248, 246);
  border-color: rgb(255, 255, 255);
  margin-left: 10px;

}

.view-details:hover {
  cursor: pointer;
  background-color: #1976D2;
  /* height:40px; */
  box-shadow: 1px 1px 1px 2px rgb(242, 244, 242);


}

.flex-display-container {
  display: flex;
  flex-direction: row;
}

.left-display-container {
  text-align: left;
}

.label {
  font-size: 15px;
  font-weight: 600;
  color: #558AFF;
}

.value {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.value-left {
  font-size: 14px;
  /* text-align: left; */
  font-weight: normal;
}

.uploaded-doc-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}

.file-design {
  text-align: left;
  padding-top: 10px;
}



.doc-image:hover {
  cursor: pointer;
}

.doc-container {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
}

.doc-name {
  font-size: 11px;
  font-weight: bold;
}

.date-name {
  font-size: 11px;
  font-weight: bold;
  margin-left: 5px;

}

.cat-name {
  font-size: 11px;
  font-weight: bold;
  margin-left: 10px;

}

.col-xl-2:hover {
  cursor: pointer;
}

.text-value {
  padding-top: 10px;
  font-size: 12px, ;
  font-weight: 600;
}

.tap-on {
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: left;
  margin-left: 5px;
  margin-top: 5px;
}

.no-records {
  font-size: 13px;
  font-weight: 600;
  color: black;
  text-align: left;
  margin-left: 5px;
  ;
  margin-top: 20px;
}

.checkin-popup {

  height: auto;
  width: auto;
  padding-top: 10px;
  /* padding-bottom: 5px; */
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  border: 1px solid rgb(203, 255, 217);
  box-shadow: 2px 2px 2px 2px #dad9d9;
  margin-top: 15px;
  padding-bottom: 5px;
  background-color: white;
  padding-bottom: 20px;
}

.const-details-rap {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}

.flex-div {
  flex-direction: row;
  display: flex;
}

.main-column-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}

.checkout-heading {

  margin-top: 30px;
  font-size: 17px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.camera_open_section {
  margin-top: 5px;
  margin-left: 10px;
  width: 99%;
  /* height:320px; */
  /* background-color:rgb(240, 240, 240); */

}

.documents {
  text-align: 'center';
  border-radius: 100px;
  height: 50px;
  width: 50px;
  background-color: #ffffff;
  box-shadow: 2px 3px 2px 2px #d4bebe;
  /* margin-left: 30px; */
}

.documents:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 3px 2px 2px rgb(242, 244, 242);
}

.complete_consult {
  border-radius: 30px;
  height: 50px;
  width: 50px;
  background-color: rgb(255, 255, 255);
  border-color: white;
  border-bottom: white;
  border-right: white;
  box-shadow: 2px 3px 2px 2px #d4bebe;
  margin-left: 30px;

}

.complete_consult:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  box-shadow: 2px 3px 2px 2px rgb(242, 244, 242);
}



.common_button {
  margin-top: 10px;
  padding-bottom: 3px;
  height: 35px;
  width: 'auto';
  padding-left: 5px;
  padding-right: 5px;
  background-color: rgb(255, 255, 255);
  font-size: small;
  color: black;
  border-radius: 8px;
  border: 1px solid rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
  /* margin-left: -80px; */
  box-shadow: 2px 3px 2px 2px #d4bebe;


}

.common_button:hover {
  cursor: pointer;
  box-shadow: 2px 3px 2px 2px rgb(242, 244, 242);
  background-color: rgb(255, 255, 255);
}

.prescription-container {
  background-color: #FFFAFA;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  /* width: 595px; */
  width: 900px;
  border: 1px solid gray;
  padding-bottom: 30px;
}

.checkin-form {
  background-color: #FFFAFA;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  max-width: 700px;
  border: 1px solid gray;
  border-radius: 5px;
  padding-bottom: 30px;
}

.fileinputstyle {
  visibility: hidden;
  opacity: 0;
}

.custom-top-button {
  background-color: white;
  border-radius: 5px;
  padding-top: 2px;
  width: 125px;
  padding-bottom: 2px;
  margin-left: -25px;


}

.custom-top-button:hover {
  cursor: pointer;

}

.medicalbill-container {
  background-color: #FFFAFA;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  /* width: 595px; */
  width: 750px;
  border: 1px solid gray;
  padding-bottom: 30px;
}

.button-hover:hover {
  cursor: pointer;
  border: 1px solid blue;
}

.header-bar {
  background-color: #1A364C;
  height: 25px;
  font-size: 13px, ;
  color: white;
  padding-top: 7px, ;
  font-weight: bold;
  text-align: center;
}

.apollo-image-container {
  text-align: right;
  padding-right: 15px;
  margin-top: 10px;
}

.dr-details {
  width: 330px;
  text-align: left;
  margin-left: 30px;
}

.dr-details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 480px;
}

.reg-no {
  display: flex;
  flex-direction: row;
  width: 480px;
  text-align: left;
  margin-left: 30px;
}

.logout-icon:hover {
  cursor: pointer;
  display: flex;
  flex-direction: row;

}

#medicinetable th {
  height: 30px;
  width: '100%';
  background-color: rgb(220, 218, 218);
}

#medicineprescriptiontable th {
  height: 30px;
  width: 100%;
  background-color: rgb(196, 194, 194);
}

.custom-card {
  height: auto;
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  border: 1px solid rgb(203, 255, 217);
  box-shadow: 2px 2px 2px 2px #dad9d9;
  margin-top: 5px;


}

.custom-card-consultation {
  height: auto;
  width: auto;
  padding-top: 10px;
  /* padding-bottom: 5px; */
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  border: 1px solid rgb(203, 255, 217);
  box-shadow: 2px 2px 2px 2px #dad9d9;
  margin-top: 15px;


}

.custom-card-consultation-history {
  height: auto;
  width: auto;
  padding-top: 10px;
  /* padding-bottom: 5px; */
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  border: 1px solid rgb(203, 255, 217);
  box-shadow: 2px 2px 2px 2px #dad9d9;
  margin-top: 15px;
  padding-bottom: 5px;
  background-color: white;


}

.custom-card-consultation:hover {
  cursor: pointer;

}

.custom-card-consultation-history:hover {
  cursor: pointer;

}

.edit-profile:hover {
  cursor: pointer;

}

.hoveranker:hover {
  cursor: pointer;

}

.circle {

  clip-path: circle(50%);
  height: 1em;
  width: 1em;
  margin-top: 4px;
  margin-inline-end: 10px;
}

.back-btn {
  @media (max-width: 400px) {
    margin-left: 160px;
  }
}

.opd_flow_text {
  text-align: center;
  font-size: 40px;
  font-weight: bold;

  @media (max-width: 400px) {
    font-size: 31px;

  }
}

.opd_flow_img {
  width: 1082px;

  @media (max-width: 400px) {
    width: 300px;


  }
}

.opd_flow_text2 {
  font-size: 19px;
  font-weight: bold;
  color: #000000;

  @media (max-width: 400px) {
    font-size: 10px;
    font-weight: bold;
    color: #000000;


  }
}